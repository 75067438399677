import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/app/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/page/common/header/DownloadAppBar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/wechatAuth/content.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/public/css/globals.css");
;
import(/* webpackMode: "eager" */ "/app/public/css/reset.css");
;
import(/* webpackMode: "eager" */ "/app/public/font/circularstd/stylesheet.css");
;
import(/* webpackMode: "eager" */ "/app/public/imgs/translate.svg");
